<template>
  <div class="managerUserOrderView">
    <view-frame :titleObject="titleObject" :buttonObj="buttonObj">
      <template #rightBtn="scope">
        <template v-if="custName && custName.member_actions && custName.member_actions.default_actions">
          <el-button v-show="item.action !== 'view'" v-for="(item, index) in custName.member_actions.default_actions" :key="'default_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleClick(item, scope.row)">{{item.name}}</el-button>
        </template>
        <template v-if="custName && custName.member_actions && custName.member_actions.custom_actions">
          <el-button v-for="(item, index) in custName.member_actions.custom_actions" :key="'custom_actions' + index" :type="item.type && item.type === 'warn' ? 'danger' : 'primary'" size="mini" @click="handleCusClick(item)">{{item.name}}</el-button>
        </template>
      </template>
      <template>
        <el-card>
          <el-row>
            <el-col :span="24">
            <span class="view__text">{{$t('form.product.basicMsg')}}</span>
            <el-card class="animate__animated animate__fadeInDown" shadow="hover">
              <custome-table tableType="object" :custSpan="6" :data="custName.basic_information" :dataItem="basicInformation"></custome-table>
            </el-card>
            </el-col>
          </el-row>
        </el-card>
        <el-card class="margintop20">
          <el-row>
            <el-col :span="24">
              <span class="view__text">{{$t('form.common.changeHistory')}}</span>
              <el-card class="animate__animated animate__fadeInUp" shadow="hover">
                 <span class="view__text"></span>
                 <createTable :autoWidth="true" :tableDataItem="historyTableItem" :operateVisible="false" :tableData="historyTableData"></createTable>
              </el-card>
            </el-col>
          </el-row>
        </el-card>
      </template>
    </view-frame>
  </div>
</template>

<script>
import loading from '@/utils/loading.json'
import { getAttractionsDetails, deleteAttractionsDetails } from '@/services/attractions'
import history from '@/common/data/jsonData'
import { getHistoryList } from '@/services/resource'
import createTable from '@/components/createTable'
import viewFrame from '@/components/viewFrame'
import customeTable from '@/components/customeTable'
import jsonData from './jsonData'
// import request from '@/utils/request'
import { mixin } from '@/mixins/viewDiolog'

export default {
  components: {
    createTable,
    viewFrame,
    customeTable
  },
  mixins: [mixin],
  data () {
    return {
      validateVisible: false,
      buttonObj: [],
      titleObject: {},
      custName: {},
      formData: {},
      modifyData: {},
      type: '',
      historyTableItem: history.historyTableItem,
      basicInformation: jsonData.basicInformation,
      dialog: {
        delOderItem: {
          visible: false,
          data: null,
          formDataRules: {
            quantity: [
              { required: true, message: this.$t('text.required'), trigger: 'change' }
            ]
          },
          formData: {
            quantity: 1
          }
        }
      },
      historyTableData: []
    }
  },
  beforeRouteLeave (to, from, next) {
    // 导航离开该组件的对应路由时调用
    // 可以访问组件实例 `this`
    this.validateVisible = false
    next()
  },
  created () {
    this.init()
  },
  methods: {
    // 初始化
    async init () {
      this.$store.state.app.loading = this.$loading(loading)
      const res = await getAttractionsDetails(this.$route.params.id)
      if (res.status === 200) {
        this.custName = res.data.site
        Object.assign(this.titleObject, { title: this.$t('form.attractions.siteName') + this.$t('text.symbol'), data: this.custName.site_name ? this.custName.site_name : '-' })
      }
      this.$store.state.app.loading.close()
      this.getDetail()
    },
    getDetail () {
      //  获取历史操作
      getHistoryList({ item_type: 'Site', item_id: this.$route.params.id }).then(res => {
        if (res.status === 200) {
          this.historyTableData = res.data.versions
        }
      })
    },
    async dialogSearch () {
      this.init()
    },
    gobackRouter () {
      this.$router.go(-1)
    },
    // 点击操作按钮
    async handleClick (type, data) {
      if (type?.action === 'edit') {
        this.$router.push({
          name: 'attractionsEdit',
          params: {
            id: this.$route.params.id
          }
        })
      }
      if (type?.action === 'destroy') {
        this.deleteClick(this.$route.params.id, deleteAttractionsDetails, 'attractionsList')
      }
    }
    // handleDelOderItemDialogCancelClick () {
    //   this.dialog.delOderItem.visible = false
    // },
    // handleDelOderItemDialogOkClick () {
    //   this.$refs.delOderItemDialogForm.validate((valid) => {
    //     if (valid) {
    //       const data = this.dialog.delOderItem.data
    //       const requestOption = data.member_actions.custom_actions[0]
    //       this.$store.state.app.loading = this.$loading(loading)
    //       request({
    //         method: requestOption.method,
    //         url: `/cms/${requestOption.path}`,
    //         data: {
    //           [requestOption.extra_params[0].key]: this.dialog.delOderItem.formData.quantity
    //         }
    //       }).then(res => {
    //         this.$message({
    //           type: 'success',
    //           message: this.$t('message.operateSuccess')
    //         })
    //         this.dialog.delOderItem.visible = false
    //         this.init()
    //       }).catch(() => this.$store.state.app.loading.close())
    //     }
    //   })
    // },
    // handleOrderItemsTableForwardToPage (data, name) {
    //   if (name === 'shop_item') {
    //     this.$router.push({
    //       name: 'goodsManagerProductDetail',
    //       params: {
    //         id: data.id
    //       }
    //     })
    //   } else if (name === 'shop_item_stock') {
    //     this.$router.push({
    //       name: 'goodsManagerVariationsDetail',
    //       params: {
    //         id: data.id
    //       }
    //     })
    //   }
    // }
  }
}
</script>

<style lang="scss" scope>
.managerUserOrderView {
  .del-oder-item-dialog-body {
    .heder {
      display: flex;
      align-items: center;
      margin-bottom: 12px;
      .avatar {
        flex-shrink: 0;
      }
    }
    .info-box {
      margin-left: 12px;
      margin-bottom: -12px;
      .title {
        margin-bottom: 12px;
      }
    }
    .notice {
      margin-bottom: 12px;
    }
  }
}
</style>
