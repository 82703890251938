// import i18n from '@/i18n/index'

export default {
  basicInformation: [{
    prop: 'online',
    back: true
  }, {
    prop: 'status'
  }, {
    prop: 'name'
  }, {
    prop: 'name_en'
  }, {
    prop: 'mini_page',
    span: 24
  }, {
    prop: 'lat'
  }, {
    prop: 'lon'
  }, {
    prop: 'site'
  }, {
    prop: 'description',
    span: 24
  }, {
    prop: 'description_en',
    span: 24
  }, {
    prop: 'short_description',
    span: 12
  }, {
    prop: 'short_description_en',
    span: 12
  }, {
    prop: 'level'
  }, {
    prop: 'ticket_type'
  }, {
    prop: 'best_price'
  }, {
    prop: 'rating'
  }, {
    prop: 'visiting_time'
  }, {
    prop: 'city'
  }, {
    prop: 'address'
  }, {
    prop: 'website'
  }, {
    prop: 'contact_number'
  }, {
    prop: 'email'
  }, {
    prop: 'operation_time'
  }, {
    prop: 'operation_time_en'
  }, {
    prop: 'ticket_strategy',
    span: 24
  }, {
    prop: 'ticket_strategy_en',
    span: 24
  }, {
    prop: 'traffic_strategy',
    span: 24
  }, {
    prop: 'traffic_strategy_en',
    span: 24
  }, {
    prop: 'tips',
    span: 24
  }, {
    prop: 'tips_en',
    span: 24
  }, {
    prop: 'global_labels'
  }, {
    prop: 'redeem_code'
  }, {
    prop: 'audio_files'
  }, {
    prop: 'image',
    image: true
  }, {
    prop: 'created_at'
  }, {
    prop: 'updated_at'
  }]
}
